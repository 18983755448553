import React, { useState } from "react";
import "./Contact.css";
import { Link } from "react-router-dom";
import country from "../PostProperty/country.json";
import { API } from "../../API/RealEstate_API";
import { useNavigate } from "react-router-dom";

function Contact() {
  const navigate = useNavigate();
  const [contactData, setContactData] = useState({
    name: "",
    address: "",
    email: "",
    phone: "",
    country: "",
    contact_for: "",
    details: "",
  });

  const contactChange = (e) => {
    setContactData({ ...contactData, [e.target.name]: e.target.value });
  };

  const contactSubmit = (e) => {
    e.preventDefault();
    var val=document.forms["contact_form"];
    if(!val["name"].value){
      document.getElementById("name_error").style.display="block";
    }
    else if(!val["email"].value){
      document.getElementById("email_error").style.display="block";
      document.getElementById("name_error").style.display="none";
    }
    else if(!val["phone"].value){
      document.getElementById("phone_error").style.display="block";
      document.getElementById("email_error").style.display="none";
      document.getElementById("name_error").style.display="none";
    }
    else if(!val["country"].value){
      document.getElementById("country_error").style.display="block";
      document.getElementById("phone_error").style.display="none";
      document.getElementById("email_error").style.display="none";
      document.getElementById("name_error").style.display="none";
    }
    else if(val["name"].value && val["email"].value && val["phone"].value && val["country"].value){
      API.AddContact(contactData).then((response)=>{
        if(response.success===true){
          navigate("/thanks");
        }
      })
    }
  };
  return (
    <div className="contact-container">
      <div className="container">
        <div className="row">
          <h4 className="mt-5 cont-head">
            <span>UPRERA Reality</span> Property Dealer
          </h4>
          <div className="col-md-6 mb-5 bg-white p-5">
            <div className="owner-contact">
              <i className="fa-solid fa-user fs-4 " />
              <span className="mx-3">Contact Person</span>
              <p>Sunil Sir</p>
            </div>
            <hr className="w-75" />
            <div className="owner-contact">
              <i className="fa-solid fa-location-dot fs-4" />
              <span className="mx-3">Address</span>
              <p>Shahi Apartment 3rd Floor Near Old Post Office,
              <br/>
              Indian Oil Petrol Pump, Mahmoorganj, Varanasi,
             <br/>
               Uttar Pradesh 221010</p>
            </div>
            <hr className="w-75" />
            <div className="owner-contact">
              <i className="fa-solid fa-phone fs-4" />
              <span className="mx-3">Mobile</span>
              <p>+91-9795457341</p>
            </div>
            <hr className="w-75" />
            <div className="owner-contact">
              <i className="fa-solid fa-envelope fs-4" />
              <span className="mx-3">Email</span>
              <p>
                <Link to="" className="text-decoration-none text-dark">
                  uprera.reality@gmail.com
                </Link>
              </p>
            </div>
            <hr className="w-75" />
            <div className="owner-contact">
              <i className="fa-solid fa-globe fs-4" />
              <span className="mx-3">Web Page</span>
              <p>
                <Link to="" className="text-decoration-none text-dark">
                www.uprerareality.com
                </Link>
              </p>
            </div>
          </div>
          <div className="col-md-6 mb-5 bg-white p-3">
            <h4 className="text-center">Contact Us</h4>
            <form className="contact-form" id="contact_form" onSubmit={contactSubmit}>
              <div className="form-group">
                <label htmlFor="name">Name</label>
                <i className="fa-solid fa-star-of-life " />
                <input
                  type="text"
                  className="form-control"
                  name="name"
                  id="name"
                  value={contactData.name}
                  onChange={contactChange}
                />
                <p id="name_error">Please Enter Name</p>
              </div>
              <div className="form-group">
                <label htmlFor="address">Address</label>
                <input
                  type="text"
                  className="form-control"
                  name="address"
                  id="address"
                  value={contactData.address}
                  onChange={contactChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="email">Email</label>
                <i className="fa-solid fa-star-of-life" />
                <input
                  type="email"
                  className="form-control"
                  name="email"
                  id="email"
                  value={contactData.email}
                  onChange={contactChange}
                />
                <p id="email_error">Please Enter Email</p>
              </div>
              <div className="form-group">
                <label htmlFor="phone">Phone</label>
                <i className="fa-solid fa-star-of-life" />
                <input
                  type="text"
                  className="form-control"
                  name="phone"
                  id="phone"
                  value={contactData.phone}
                  onChange={contactChange}
                />
                <p id="phone_error">Please Enter Phone Number</p>
              </div>
              <div className="form-group">
                <label htmlFor="country">Country</label>
                <i className="fa-solid fa-star-of-life" />
                <select name="country" id="country" value={contactData.country} onChange={contactChange}>
                  {country.map((data) => {
                    return (
                      <option value={data.name==="" ? "":data.name} key={data.code}>
                        {data.name==="" ? "Select":data.name}
                      </option>
                    );
                  })}
                </select>
                <p id="country_error">Please Enter Country</p>
              </div>
              <div className="form-group ">
                <label htmlFor="contact_for">I want to</label>
                <select name="contact_for" id="contact_for" value={contactData.contact_for} onChange={contactChange}>
                  <option value="select">--Select--</option>
                  <option value="Buy a property">Buy a property</option>
                  <option value="Rent-In property">Rent-In property</option>
                  <option value="Rent-Out property">Rent-Out property</option>
                  <option value="Sell a property">Sell a property</option>
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="comments">Details</label>
                <textarea
                  cols={46}
                  rows={3}
                  name="details"
                  id="comments"
                  value={contactData.details}
                  onChange={contactChange}
                />
              </div>
              <button type="submit" className="btn btn-danger mt-3">
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
