import React, { useEffect, useState } from "react";
import Dashboard from "../Dashboard";
import { API, imageUrl } from "../../../API/RealEstate_API";
import { ToastContainer, toast } from "react-toastify";

function AllProperty() {
  const [fetchData, setFetchData] = useState([]);

  useEffect(() => {
    API.AllProperty().then((response) => {
      // console.log(response.data)
      setFetchData(response.data);
    });
  });

  const removeProperty = (id) => {
    API.DeletePropertyById(id).then((response) => {
      toast.success(response.message);
    });
    API.AllProperty().then((response) => {
      if(response.data.length>0){
        setFetchData(response.data);
      }
      else{
        setFetchData([]);
      }
    });
  };
  return (
    <>
      <ToastContainer position="top-center" theme="light" />
      <div className="row">
        <h4 style={{ textAlign: "center" }}>All property</h4>
        {fetchData.length>0?(fetchData.map((data) => {
          return (
            <div
              className="col-xl-3 col-md-6 mb-4 mt-3 text-center"
              key={data.id}
            >
              <div className="card border-left-success shadow h-100 ">
                <img
                  src={data.property_image ?
                    imageUrl + data.property_image
                    :"Image/no-image.png"
                  }
                  className="card-img-top h-50"
                  alt="property-pic"
                />
                <div className="card-img-overlay">
                  <span className="bg-danger text-white pt-1 pb-1 px-2">
                    Property Id: {data.id}
                  </span>
                </div>
                <div className="card-body">
                  <div className="row no-gutters align-items-center">
                    <div className="col mr-2">
                      <div className="text-lg font-weight-bold text-primary">
                        {data.property_category}
                      </div>
                      <div className="text-success  mb-1" style={{fontSize:"13px"}}>
                        {data.description}({data.property_area})
                      </div>
                      <div className="h5 mb-0 font-weight-bold text-gray-800">
                        <i className="fa fa-inr text-gray-600 me-2"/>
                        {data.price}
                      </div>
                      <div className="h6 mb-0 font-weight-bold text-gray-800 mt-1">
                        <i className="fa fa-location-arrow text-gray-600 me-2" />
                        {data.locality},{data.city}
                      </div>
                      <div className="h6 mb-0 font-weight-bold text-gray-800 mt-2">
                        <button
                          type="button"
                          className="btn btn-danger btn-sm mx-auto d-block"
                          onClick={() => removeProperty(data.id)}
                        >
                          Remove
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })):(<p className="text-center mt-5" style={{fontSize:"16px"}}>No Properties Available</p>)}
      </div>
    </>
  );
}

export default Dashboard(AllProperty);
