import React, { useEffect, useState } from "react";
import Dashboard from "../Dashboard";
import "../Dashboard.css";
import { API } from "../../../API/RealEstate_API";

function AdminHome() {
  const [city, setCity] = useState([]);
  const [countCity, setCountCity] = useState({});

  useEffect(() => {
    API.AllProperty().then((response) => {
      let countObj = {};
      const lowerCity = response.data.map((item) => {
        return item.city.toLowerCase();
      });
      lowerCity.forEach((item) => {
        countObj[item]=(countObj[item] || 0)+1;
      });
      setCountCity(countObj);
      const distinctCity = response.data.map((item) => {
        return item.city.toLowerCase();
      });
      const filterData = distinctCity.filter(
        (value, index, self) => self.indexOf(value) === index
      );
      setCity(filterData);
    });
  }, []);

  return (
    <div className="row mt-5">
      <h4 style={{ textAlign: "center" }}>Property in City</h4>
      {city.map((data, index) => {
        return (
          <div className="col-xl-3 col-md-6 mb-4 text-center mt-3" key={index}>
            <div className="card border-left-success shadow h-100 py-2">
              <div className="card-body">
                <div className="row no-gutters align-items-center">
                  <div className="col mr-2">
                    <div className="text-xs fs-3 font-weight-bold text-danger text-uppercase mb-1">
                      {countCity[data] ? countCity[data] : ""}
                    </div>
                    <div className="h6 mb-0 font-weight-bold text-gray-800">
                      Properties in {data}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default Dashboard(AdminHome);
