import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./SingleProperty.css";
import { API, imageUrl } from "../../API/RealEstate_API";
import { ToastContainer, toast } from "react-toastify";

function SingleProperty({ singleProperty, callbackProperty }) {
  const navigate = useNavigate();
  const [cityProperty, setCityProperty] = useState([]);
  const [propertyEnquiry, setPropertyEnquiry] = useState({
    name: "",
    email: "",
    mobile_no: "",
    enquiry_message:""
  });
  useEffect(() => {
    singleProperty.map(async (data) => {
      await API.AllProperty().then((response) => {
        const citydata = response.data.filter(
          (item) => item.city.toLowerCase() === data.city.toLowerCase()
        );
        setCityProperty(citydata);
      });
    });
  }, [singleProperty]);
  
  const propertyEnquiryChange = (e) => {
    setPropertyEnquiry({ ...propertyEnquiry, [e.target.name]: e.target.value });
  };

  const propertyEnquirySubmit = (e,id) => {
    e.preventDefault();

    let val = document.forms["property-enquiry"];
    if (!val["name"].value) {
      document.getElementById("name_error").style.display = "block";
    } else if (!val["email"].value) {
      document.getElementById("email_error").style.display = "block";
      document.getElementById("name_error").style.display = "none";
    } else if (!val["mobile_no"].value) {
      document.getElementById("phone_error").style.display = "block";
      document.getElementById("name_error").style.display = "none";
      document.getElementById("email_error").style.display = "none";
    }
    else if (!val["enquiry_message"].value) {
      document.getElementById("message_error").style.display="block";
      document.getElementById("phone_error").style.display = "none";
      document.getElementById("name_error").style.display = "none";
      document.getElementById("email_error").style.display = "none";
    }
     else if (
      val["name"].value &&
      val["email"].value &&
      val["mobile_no"].value && 
      val["enquiry_message"].value
    ) {
      document.getElementById("phone_error").style.display = "none";
      document.getElementById("name_error").style.display = "none";
      document.getElementById("email_error").style.display = "none";
      document.getElementById("message_error").style.display = "none";
      API.AddPropertyEnquiry({...propertyEnquiry,property_id:id}).then((response)=>{
        if(response.success===true){
          toast.success(response.message);
        }
      })
    }
    setPropertyEnquiry({
      name: "",
      email: "",
      mobile_no: "",
      enquiry_message:""
    })
  };

  const PropertyByCity = (id) => {
    localStorage.setItem("propertyId", JSON.stringify(id));
    API.FindPropertyById(id).then((response) => {
      callbackProperty(response.data);
      navigate("/single-property");
    });
  };
  return (
    <div className="container">
      <ToastContainer position="top-center" theme="light" />
      <div className="row mt-5">
        {singleProperty.map((data) => {
          return (
            <div key={data.id}>
              <div className="single-div">
                <div className="col-lg-7 col-md-7 mb-4 mt-3">
                  <h4 className="fw-bold">
                    {data.property_area} {data.description}, {data.city}
                  </h4>
                  <div className="card shadow rounded-0">
                    <img
                      src={
                        data.property_image
                          ? imageUrl +
                            data.property_image
                          : "Image/no-image.png"
                      }
                      className="card-img-top rounded-0"
                      alt="property-pic"
                    />
                    <div className="card-img-overlay">
                      <span className="bg-danger text-white pt-2 pb-2 px-4">
                        Property Id: {data.id}
                      </span>
                    </div>
                    <div className="card-body mx-3">
                      <div className="row no-gutters align-items-center">
                        <div className="col mr-2">
                          <div className="text-md mb-1 fw-bold mx-1">
                            Property Area- ({data.property_area})
                          </div>
                          <div className="h6 mb-0 text-dark-800">
                            <i className="fa fa-inr text-dark-600 me-2 mx-1" />
                            {data.price}
                          </div>
                          <div className="h6 mb-0  mt-1">
                            <i className="fa fa-location-arrow me-2" />
                            {data.locality},{data.city}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-5 col-md-5 mt-5">
                  <div className="single-enquiry">
                    <h4 className="mt-4">Send an enquiry for this property?</h4>
                    <p>Contact Person : name here</p>
                    <form
                      onSubmit={(e)=>propertyEnquirySubmit(e,data.id)}
                      id="property-enquiry"
                    >
                      <div className="form-group mb-4">
                        <input
                          type="text"
                          id="name"
                          name="name"
                          value={propertyEnquiry.name}
                          className="form-control"
                          placeholder="Name"
                          onChange={propertyEnquiryChange}
                        />
                        <p id="name_error">Enter Name</p>
                      </div>

                      <div className="form-group mb-4">
                        <input
                          type="email"
                          id="email"
                          name="email"
                          value={propertyEnquiry.email}
                          className="form-control"
                          placeholder="Email"
                          onChange={propertyEnquiryChange}
                        />
                        <p id="email_error">Enter Email</p>
                      </div>
                      <div className="form-group mb-4">
                        <input
                          type="tel"
                          id="mobile_no"
                          name="mobile_no"
                          value={propertyEnquiry.mobile_no}
                          placeholder="Mobile No"
                          pattern="[0-9]{10}"
                          className="p-2"
                          onChange={propertyEnquiryChange}
                        />
                        <p id="phone_error">Enter Mobile No</p>
                      </div>
                      <div className="form-group mb-4">
                        <textarea
                          value={propertyEnquiry.enquiry_message}
                          rows={4}
                          id="enquiry_message"
                          name="enquiry_message"
                          placeholder="Details"
                          style={{ opacity: ".7" }}
                          onChange={propertyEnquiryChange}
                          className="px-2 pt-1"
                        />
                        <p id="message_error">Details required</p>
                      </div>
                      <button type="submit" className="single-property-btn">
                        Send Enquiry
                      </button>
                    </form>
                  </div>
                </div>
              </div>
              <h3 className="text-center mt-5">
                Related Properties in {data.city}
              </h3>
            </div>
          );
        })}
      </div>
      <div className="row mt-3">
        {cityProperty.map((data) => {
          return (
            <div className="col-md-4 mb-4 cityproperty" key={data.id}>
              <Link
                onClick={() => PropertyByCity(data.id)}
                style={{ textDecoration: "none" }}
              >
                <div className="card">
                  <img
                    src={
                      data.property_image
                        ? imageUrl + data.property_image
                        : "Image/no-image.png"
                    }
                    className="card-img-top rounded-0"
                    alt="property"
                  />
                  <div className="card-img-overlay">
                    <span className="bg-dark rounded-2 text-white pt-2 pb-2 px-4">
                      For {data.property_type}
                    </span>
                  </div>
                  <div className="card-body text-center">
                    <p className="card-text">
                      {data.description}({data.property_area})
                    </p>
                    <p className="text-dartk fs-6">
                      <i className="fa fa-inr mt-2"></i>&nbsp;
                      {data.price}
                    </p>
                  </div>
                  <div className=" card-footer rounded-0 text-center bg-warning">
                    <i className="fa-solid fa-location-dot text-gray-800 me-2" />
                    {data.locality},{data.city}
                  </div>
                </div>
              </Link>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default SingleProperty;
