import React, { useEffect, useState } from "react";
import "../About/About.css";
import { Link } from "react-router-dom";
import { API } from "../../API/RealEstate_API";

function PropertyByCity() {
  const [allProperty, setAllProperty] = useState([]);

  useEffect(() => {
    API.AllProperty().then((response) => {
      setAllProperty(response.data?response.data:[]);
    });
  }, []);
  return (
    <div className="col-md-4" style={{ marginTop: "90px" }}>
      <div className="card property-card">
        <h5 className="card-title text-center mt-2 text-primary">Property</h5>
        {allProperty.map((item) => {
          return (
            <div key={item.id} className="side-p">
              <hr />
              <Link
                className="link mx-1"
                style={{ fontSize: "12px", fontWeight:"400", textAlign: "justify" }}
              >
                {item.description}
              </Link>
            </div>
          );
        })}
      </div>
      <div className="card property-card mt-4 text-dark mb-4">
        <h5 className="card-title text-center text-primary mt-2">Contact Us</h5>
        <hr />
        <p className="mx-3">
          <b>Address:</b> Shahi Apartment 3rd Floor Near Old Post Office,
              
               Indian Oil Petrol Pump, Mahmoorganj, Varanasi,
              
                Uttar Pradesh 221010
        </p>
        <hr />
        <p className="mx-3">
          <b>Phone:</b>+91-9795457341
        </p>
        <hr />
        <p className="mx-3">
          <b>Email ID:</b>
          <Link>uprera.reality@gmail.com</Link>
        </p>
        <hr />
        <p className="mx-3">
          <b>Call Us:</b>+91-9795457341
        </p>
      </div>
    </div>
  );
}

export default PropertyByCity;
