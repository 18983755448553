import React from "react";
import "./Popup.css";
// import FontAwesome from "react-fontawesome";
import { Link } from "react-router-dom";
const $ = require("jquery");

export default function Popup() {
  const popupClick = () => {
    $("#popup-form").slideToggle();
  };
  return (
    <>
    <Link to='https://api.whatsapp.com/send?phone=919795457341' target='_blank'>
    <div className="popup-button" onClick={popupClick}>
          <button className="pop-button"></button>
      </div>
    </Link>
      

      {/* <form id="popup-form" style={{ display: "none" }}>
        <div className="popup-head">
        <i className="fa-solid fa-xmark" onClick={popupClick}></i>
        <FontAwesome
            name="icon"
            className="fa-brands fa-whatsapp whatsapp-icon fs-2 mt-2"
          />
          <span className="head mx-3">Raise your Query</span>
          <p className="para">Hi! Simply click below and type your query.</p>
        </div>
        <div className="popup-footer">
            <p className="">Our expert will reply soon..</p>
            <Link to='https://api.whatsapp.com/send?phone=1234567890'>
            <button type="button" className="btn foot">
            <FontAwesome
            name="icon"
            className="fa-brands fa-whatsapp text-success  fs-1  me-3 mx-3"
          />
          Click Here to Chat
          <FontAwesome
            name="icon"
            className="fa-brands fa-whatsapp text-success fs-2  mx-5"
          />
            </button>
            </Link>
          </div>
      </form> */}
    </>
  );
}
