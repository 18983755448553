import React, { useEffect, useState } from "react";
import "./Dashboard.css";
import { Link } from "react-router-dom";
import { Preloader, Bars } from "react-preloader-icon";

const Dashboard = (ChildComponent) => {
  function AdminPanel() {
    const [pageLoaded, setPageLoaded] = useState(false);
    const [style, setStyle] = useState(
      "navbar-nav bg-gradient-primary sidebar sidebar-dark accordion"
    );

    useEffect(() => {
      setTimeout(() => {
        setPageLoaded(true);
      }, 1000);
    }, []);
    if (!pageLoaded) {
      return (
        <div className="loading-page">
          <div className="center">
            <Preloader
              use={Bars}
              size={60}
              strokeWidth={10}
              strokeColor="#f7b085"
              duration={600}
            />
          </div>
        </div>
      );
    }

    const changeStyle = () => {
      if (
        style ===
        "navbar-nav bg-gradient-primary sidebar sidebar-dark accordion"
      ) {
        setStyle(
          "navbar-nav bg-gradient-primary sidebar sidebar-dark accordion toggled"
        );
      } else {
        setStyle(
          "navbar-nav bg-gradient-primary sidebar sidebar-dark accordion"
        );
      }
    };

    const changeToggle = () => {
      if (
        style ===
        "navbar-nav bg-gradient-primary sidebar sidebar-dark accordion"
      ) {
        setStyle(
          "navbar-nav bg-gradient-primary sidebar sidebar-dark accordion toggled1"
        );
      } else {
        setStyle(
          "navbar-nav bg-gradient-primary sidebar sidebar-dark accordion"
        );
      }
    };

    const getLogout = () => {
      localStorage.removeItem("authType");
    };

    return (
      <div id="page-top">
        <>
          {/* Page Wrapper */}
          <div id="wrapper">
            {/* Sidebar */}
            <ul className={style} id="accordionSidebar">
              {/* Divider */}
              <hr className="sidebar-divider my-0" />
              {/* Nav Item - Dashboard */}
              <li className="nav-item active" style={{ marginTop: "40px" }}>
                <Link className="nav-link" to="/home">
                  <i className="fas fa-fw fa-tachometer-alt" />
                  <span className="text-white">Dashboard</span>
                </Link>
              </li>
              {/* Divider */}
              <hr className="sidebar-divider" />
              <li className="nav-item ">
                <Link className="nav-link" to="/home">
                  <i className="fas fa-fw fa-home" />
                  <span className="text-white">Home</span>
                </Link>
              </li>

              {/* Divider */}
              <hr className="sidebar-divider" />

              <li className="nav-item">
                <Link
                  className="nav-link collapsed"
                  data-toggle="collapse"
                  data-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  <i className="fas fa-fw fa-comment" />
                  <span className="text-white">Testimonial</span>
                </Link>
                <div
                  id="collapseOne"
                  className="collapse"
                  aria-labelledby="headingTwo"
                  data-parent="#accordionSidebar"
                >
                  <div className="bg-white py-2 collapse-inner rounded">
                    <h6 className="collapse-header">Testimonial view:</h6>
                    <Link className="collapse-item" to="/pending-testimonial">
                      Pending
                    </Link>
                    <Link className="collapse-item" to="/approved-testimonial">
                      Approved
                    </Link>
                    <Link className="collapse-item" to="/reject-testimonial">
                      Reject
                    </Link>
                  </div>
                </div>
              </li>

              {/* Divider */}
              <hr className="sidebar-divider" />
              {/* Nav Item - Pages Collapse Menu */}
              <li className="nav-item">
                <Link
                  className="nav-link collapsed"
                  data-toggle="collapse"
                  data-target="#collapseTwo"
                  aria-expanded="true"
                  aria-controls="collapseTwo"
                >
                  <i className="fas fa-fw fa-cog" />
                  <span className="text-white">Property</span>
                </Link>
                <div
                  id="collapseTwo"
                  className="collapse"
                  aria-labelledby="headingTwo"
                  data-parent="#accordionSidebar"
                >
                  <div className="bg-white py-2 collapse-inner rounded">
                    <h6 className="collapse-header">Property view:</h6>
                    <Link className="collapse-item" to="/add-property">
                      Add Property
                    </Link>
                    <Link className="collapse-item" to="/property">
                      All Property
                    </Link>
                  </div>
                </div>
              </li>

              {/* Divider */}
              <hr className="sidebar-divider" />
              <li className="nav-item">
                <Link
                  className="nav-link collapsed"
                  data-toggle="collapse"
                  data-target="#collapseThree"
                  aria-expanded="true"
                  aria-controls="collapseThree"
                >
                  <i className="fa fa-users" />
                  <span className="text-white">Property By User</span>
                </Link>
                <div
                  id="collapseThree"
                  className="collapse"
                  aria-labelledby="headingTwo"
                  data-parent="#accordionSidebar"
                >
                  <div className="bg-white py-2 collapse-inner rounded">
                    <h6 className="collapse-header">User Property:</h6>
                    <Link className="collapse-item" to="/pending-user-property">
                      Pending
                    </Link>
                    <Link
                      className="collapse-item"
                      to="/approved-user-property"
                    >
                      Approved
                    </Link>
                  </div>
                </div>
              </li>

              {/* Divider */}
              <hr className="sidebar-divider" />
              <li className="nav-item ">
                <Link className="nav-link" to="/enquiry">
                  <i className="fa fa-address-card" />
                  <span className="text-white">Enquiry</span>
                </Link>
              </li>

              {/* Divider */}
              <hr className="sidebar-divider" />
              <li className="nav-item ">
                <Link className="nav-link" to="/user-contact">
                  <i className="fa fa-address-card" />
                  <span className="text-white">Contact</span>
                </Link>
              </li>

              {/* Divider */}
              <hr className="sidebar-divider" />
              <div className="text-center d-none d-md-inline">
                <button
                  className="rounded-circle border-0"
                  id="sidebarToggle"
                  onClick={changeStyle}
                />
              </div>
            </ul>
            <div id="content-wrapper" className="d-flex flex-column">
              {/* Main Content */}
              <div id="content">
                {/* Topbar */}
                <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
                  {/* Sidebar Toggle (Topbar) */}
                  <button
                    id="sidebarToggleTop"
                    className="btn-link d-md-none"
                    style={{
                      marginLeft: "20px",
                      borderRadius: "50%",
                      color: "black",
                    }}
                    onClick={changeToggle}
                  >
                    <i className="fa fa-bars fs-4" />
                  </button>
                  {/* <span className="mt-2 mx-5">Welcome to Admin Dashboard</span> */}
                  {/* Topbar Navbar */}
                  <ul className="navbar-nav ml-auto">
                    <div className="topbar-divider d-none d-sm-block" />
                    {/* Nav Item - User Information */}
                    <li className="nav-item dropdown no-arrow">
                      <Link
                        className="nav-link dropdown-toggle"
                        to="#"
                        id="userDropdown"
                        role="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <span className="mr-2 d-inline  text-gray-800 ">
                          Admin
                        </span>
                        <img
                          className="img-profile rounded-circle"
                          src="img/undraw_profile.svg"
                          alt="img-profile"
                        />
                      </Link>
                      {/* Dropdown - User Information */}
                      <div
                        className="dropdown-menu dropdown-menu-right shadow animated--grow-in"
                        aria-labelledby="userDropdown"
                      >
                        <Link
                          to="/"
                          className="dropdown-item"
                          data-toggle="modal"
                          data-target="#logoutModal"
                          onClick={getLogout}
                        >
                          <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400" />
                          Logout
                        </Link>
                      </div>
                    </li>
                  </ul>
                </nav>
                {/* Begin Page Content */}
                <div className="container-fluid">
                  <ChildComponent />
                </div>
              </div>
            </div>
          </div>
          <Link className="scroll-to-top rounded" to="#page-top">
            <i className="fas fa-angle-up" />
          </Link>
        </>
      </div>
    );
  }
  return AdminPanel;
};

export default Dashboard;
